
.listing-card{
  @apply w-full min-h-[10px] bg-[var(--listingCardBackgroundColor)]   rounded-[var(--listingCardBorderRadius)] overflow-hidden cursor-pointer shadow-listing_card  transition ease-in-out duration-500 group-hover:bg-[var(--listingCardHoverBackgroundColor)] group-hover:shadow-listing_card_hover     gap-y-[--listingCardRowGap] gap-x-[--listingCardColumnGap]  mr-[--listingCardMarginRight] ml-[--listingCardMarginLeft] mt-[--listingCardMarginTop] mb-[--listingCardMarginBottom] pl-[--listingCardPaddingLeft] pr-[--listingCardPaddingRight] pt-[--listingCardPaddingTop] pb-[--listingCardPaddingBottom] 
  
}

 
.listing-card-image{
    @apply min-h-[--listingCardImageMinHeight] min-w-[--listingCardImageMinWidth] h-[--listingCardImageHeight] max-h-[--listingCardImageMaxHeight] max-w-[--listingCardImageMaxWidth] w-[--listingCardImageWidth] ml-[--listingCardImageMarginLeft] mr-[--listingCardImageMarginRight] mt-[--listingCardImageMarginTop] mb-[--listingCardImageMarginBottom] pl-[--listingCardImagePaddingLeft] pr-[--listingCardImagePaddingRight] pt-[--listingCardImagePaddingTop] pb-[--listingCardImagePaddingBottom]   flex-none
}

.listing-card-image-object-fit{
    object-fit: var(--listingCardImageObjectFit);
    object-position: var(--listingCardImageObjectPosition);
}

.listing-card-image-border-radius{
    @apply rounded-[--listingCardImageBorderRadius] rounded-tl-[--listingCardImageTopLeftBorderRadius] rounded-tr-[--listingCardImageTopRightBorderRadius] rounded-bl-[--listingCardImageBottomLeftBorderRadius] rounded-br-[--listingCardImageBottomRightBorderRadius]
}

 
.listing-card-title {
    @apply text-[length:var(--listingCardTitleTextSize)] text-[color:var(--listingCardTitleColor)] font-[--listingCardTitleTextFontWeight] leading-[--listingCardTitleTextLineHeight]
    mr-[--listingCardTitleMarginRight] ml-[--listingCardTitleMarginLeft] mt-[--listingCardTitleMarginTop] mb-[--listingCardTitleMarginBottom] pl-[--listingCardTitlePaddingLeft] pr-[--listingCardTitlePaddingRight] pt-[--listingCardTitlePaddingTop] pb-[--listingCardTitlePaddingBottom] 
    ;
}


.listing-card-price {
    @apply   text-[length:var(--listingCardPriceTextSize)] text-[color:var(--listingCardPriceColor)]  font-[--listingCardPriceTextFontWeight] leading-[--listingCardPriceTextLineHeight]  flex  flex-wrap gap-2   mr-[--listingCardPriceMarginRight] ml-[--listingCardPriceMarginLeft] mt-[--listingCardPriceMarginTop] mb-[--listingCardPriceMarginBottom] pl-[--listingCardPricePaddingLeft] pr-[--listingCardPricePaddingRight] pt-[--listingCardPricePaddingTop] pb-[--listingCardPricePaddingBottom]  ;
}
  
.listing-card-account {
    @apply  flex items-center   mr-[--listingCardAccountMarginRight] ml-[--listingCardAccountMarginLeft] mt-[--listingCardAccountMarginTop] mb-[--listingCardAccountMarginBottom] pl-[--listingCardAccountPaddingLeft] pr-[--listingCardAccountPaddingRight] pt-[--listingCardAccountPaddingTop] pb-[--listingCardAccountPaddingBottom]  
}
  
.listing-card-donation {
    @apply     mr-[--listingCardDonationMarginRight] ml-[--listingCardDonationMarginLeft] mt-[--listingCardDonationMarginTop] mb-[--listingCardDonationMarginBottom] pl-[--listingCardDonationPaddingLeft] pr-[--listingCardDonationPaddingRight] pt-[--listingCardDonationPaddingTop] pb-[--listingCardDonationPaddingBottom]  
}
  